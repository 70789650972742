import { useState, useEffect, useRef } from "react";
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { approveCalonPeminjam, approveRiwayatKerjasama } from "../../actions/calonPeminjamAction";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
} from "@mui/material";
import borders from "assets/theme/base/borders";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 670,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  overflow: "auto",
  p: 4,
};

function PersetujuanPeminjam(props) {

  const unit = [
    {
      "id": 1,
      "value": "percentage",
      "label": "(%) Persentase"
    },
    {
      "id": 2,
      "value": "fixed",
      "label": "(Rp) Angka Tetap"
    },

  ]


  const [kreditLimit, setKreditLimit] = useState(0);
  const [kreditLimitStr, setKreditLimitStr] = useState(
    Number(0).toLocaleString("id-ID", { style: "currency", currency: "IDR" })
  );
  const [isEditKL, setIsEditKL] = useState(false);
  const [persentasePengajuan, setPersentasePengajuan] = useState(0);
  const [interestHarian, setInterestHarian] = useState(0);
  const [interestBulanan, setInterestBulanan] = useState(0);
  const [interestTahunan, setInterestTahunan] = useState(0);
  const [minimumPeriod, setMinimumPeriod] = useState(0);
  const [penaltiBulanan, setPenaltiBulanan] = useState(0);
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [monthlyInstallment, setMonthlyInstallment] = useState(false);



  const [adminFee, setAdminFee] = useState(0);
  const [adminFeeUnit, setAdminFeeUnit] = useState(unit[0]);

  const [monthlyAdminFee, setMonthlyAdminFee] = useState(0);
  const [monthlyAdminFeeUnit, setMonthlyAdminFeeUnit] = useState(unit[0]);

  const onAdminFeeUnitChange = (e, v) => {
    setAdminFeeUnit(v);
  }

  const onMonthlyAdminFeeUnitChange = (e, v) => {
    setMonthlyAdminFeeUnit(v);
  }


  const onMonthlyInstallmentChange = (e, v) => {
    setMonthlyInstallment(v);
  }

  const [showError, setShowError] = useState({
    persentasePengajuan: false,
    interestHarian: false,
    interestBulanan: false,
    interestTahunan: false,
    all: false,
  });
  const [helperMsg, setHelperMsg] = useState({
    persentasePengajuan: "Persentase pengajuan tidak boleh 0",
    interestHarian: "Bunga harian tidak boleh 0",
    interestBulanan: "Bunga bulanan tidak boleh 0",
    interestTahunan: "Bunga tahunan tidak boleh 0",
    all: "Masih terdapat informasi wajib yang diisi 0.",
    fail: "ERROR",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (kreditLimit !== "") {
      if (kreditLimit < 0) {
        setKreditLimit(0);
      }
    }
  }, [kreditLimit]);

  useEffect(() => {
    if (persentasePengajuan !== "") {
      if (persentasePengajuan < 0) {
        setPersentasePengajuan(0);
      } else if (persentasePengajuan > 100) {
        setPersentasePengajuan(100);
      } else {
        setShowError({ ...showError, persentasePengajuan: false });
      }
    }
  }, [persentasePengajuan]);

  useEffect(() => {
    if (interestHarian !== "") {
      if (interestHarian < 0) {
        setInterestHarian(0);
      } else if (interestHarian > 100) {
        setInterestHarian(100);
      } else {
        setShowError({ ...showError, interestHarian: false });
      }
    }
  }, [interestHarian]);

  useEffect(() => {
    if (interestBulanan !== "") {
      if (interestBulanan < 0) {
        setInterestBulanan(0);
      } else if (interestBulanan > 100) {
        setInterestBulanan(100);
      } else {
        setShowError({ ...showError, interestBulanan: false });
      }
    }
  }, [interestBulanan]);

  useEffect(() => {
    if (interestTahunan !== "") {
      if (interestTahunan < 0) {
        setInterestTahunan(0);
      } else if (interestTahunan > 100) {
        setInterestTahunan(100);
      } else {
        setShowError({ ...showError, interestTahunan: false });
      }
    }
  }, [interestTahunan]);

  useEffect(() => {
    if (showError.all) {
      if (
        !(
          interestHarian <= 0 ||
          interestBulanan <= 0 ||
          interestTahunan <= 0 ||
          persentasePengajuan <= 0
        )
      ) {
        setShowError({ ...showError, all: false });
      }
    }
  }, [interestTahunan, interestBulanan, interestHarian, persentasePengajuan]);

  const onKreditLimitBlur = (param) => {
    setKreditLimitStr(
      Number(kreditLimit).toLocaleString("id-ID", { style: "currency", currency: "IDR" })
    );
    setIsEditKL(false);
    param.target.type = null;
  };

  const onInterestHarianBlur = () => {
    if (interestHarian <= 0) {
      setShowError({ ...showError, interestHarian: true });
    }
  };

  const onInterestBulananBlur = () => {
    if (interestBulanan <= 0) {
      setShowError({ ...showError, interestBulanan: true });
    }
  };

  const onInterestTahunanBlur = () => {
    if (interestTahunan <= 0) {
      setShowError({ ...showError, interestTahunan: true });
    }
  };

  const onPersentasePengajuanBlur = () => {
    if (persentasePengajuan <= 0) {
      setShowError({ ...showError, persentasePengajuan: true });
    }
  };

  const onMinimumPeriodBlur = () => {
    if (minimumPeriod <= 0) {
      setMinimumPeriod(0);
    }
  };

  const onPenaltiBulananBlur = () => {
    if (penaltiBulanan <= 0) {
      setPenaltiBulanan(0);
    }
  };

  const onKreditLimitFocus = (param) => {
    setIsEditKL(true);
    param.target.type = "number";
  };

  const onAgree = () => {
    if (
      interestHarian <= 0 ||
      interestBulanan <= 0 ||
      interestTahunan <= 0 ||
      persentasePengajuan <= 0
    ) {
      setShowError({
        persentasePengajuan: persentasePengajuan <= 0,
        interestHarian: interestHarian <= 0,
        interestBulanan: interestBulanan <= 0,
        interestTahunan: interestTahunan <= 0,
        all: true,
      });
      setOpenApproveDialog(false);
    } else {
      let other = [];
      if (minimumPeriod > 0) {
        other = [
          ...other,
          {
            name: "minimum_period",
            type: "number",
            value: Number(minimumPeriod),
          },
        ];
      }
      if (penaltiBulanan > 0) {
        other = [
          ...other,
          {
            name: "penalty",
            type: "percentage",
            value: Number(penaltiBulanan),
          },
        ];
      }
      const params = {
        relation_id: props.idrelation,
        financing_amount: Number(kreditLimit),
        financing_percentage: Number(persentasePengajuan),
        interest_base: Number(interestBulanan),
        interest_annum: Number(interestTahunan),
        other,
      };
      if (props.relation) {
        dispatch(approveRiwayatKerjasama(params));
      } else {
        dispatch(approveCalonPeminjam(params));
      }
    }
  };

  return (
    <MDBox sx={style}>
      <Grid container direction="column" justifyContent="space-around" spacing={3}>
        <Grid item>
          <MDBox display="flex" justifyContent="space-between">
            <MDBox>
              <Icon fontSize="large" sx={{ pt: 1, ml: 3 }} color="info">
                group
              </Icon>
              <MDTypography ml={2} variant="title" fontWeight="medium">
                {props.peminjam ? "Setujui Peminjam" : "Riwayat Kerjasama"}
              </MDTypography>
            </MDBox>
            <Icon sx={{ cursor: "pointer" }} onClick={props.onClose} fontSize="large">
              clear
            </Icon>
          </MDBox>
        </Grid>
        <Grid item>
          <MDTypography color="black" fontSize="16px" fontWeight="medium" pb={2}>
            Umum
          </MDTypography>
          <MDBox sx={{ width: "100%", mt: 1 }}>
            <Grid
              container
              // direction="column"
              // alignItems="center"
              // justifyContent="space-around"
              spacing={2}
            >
              <Grid item mb={2}
              // xs={12} md={6}
              >
                <MDInput
                  id="kredit_limit"
                  name="kredit_limit"
                  label="Kredit Limit (Rp)"
                  value={isEditKL ? kreditLimit : kreditLimitStr}
                  onChange={(e) => setKreditLimit(e.target.value)}
                  onBlur={onKreditLimitBlur}
                  onFocus={onKreditLimitFocus}
                  placeholder="Kredit limit yang disetujui"
                  sx={{ width: 300 }}
                />
              </Grid>
              <Grid item mb={2}
              // xs={12} md={6}
              >
                <MDInput
                  id="financing_percentage"
                  name="financing_percentage"
                  label="Maksimal Persentase Pengajuan (%)"
                  value={persentasePengajuan}
                  type="number"
                  onChange={(e) => setPersentasePengajuan(e.target.value)}
                  onBlur={onPersentasePengajuanBlur}
                  placeholder="Nilai maksimal persentase dari nilai invoice yang dapat diajukan"
                  required
                  sx={{ width: 300 }}
                />
                <MDTypography color="error" sx={{ fontSize: 10 }}>
                  {showError.persentasePengajuan ? helperMsg.persentasePengajuan : ""}{" "}
                </MDTypography>
              </Grid>
              <Grid item mb={2}>
                <MDInput
                  id="interest_harian"
                  name="interest_harian"
                  label="Bunga Harian (%)"
                  value={interestHarian}
                  type="number"
                  onChange={(e) => setInterestHarian(e.target.value)}
                  onBlur={onInterestHarianBlur}
                  placeholder="Bunga harian yang disetujui"
                  required
                  sx={{ width: 300 }}
                />
                <MDTypography color="error" sx={{ fontSize: 10 }}>
                  {showError.interestHarian ? helperMsg.interestHarian : ""}{" "}
                </MDTypography>
              </Grid>
              <Grid item mb={2}>
                <MDInput
                  id="interest_bulanan"
                  name="interest_bulanan"
                  label="Bunga Bulanan (%)"
                  value={interestBulanan}
                  type="number"
                  onChange={(e) => setInterestBulanan(e.target.value)}
                  onBlur={onInterestBulananBlur}
                  placeholder="Bunga bulanan yang disetujui"
                  required
                  sx={{ width: 300 }}
                />
                <MDTypography color="error" sx={{ fontSize: 10 }}>
                  {showError.interestBulanan ? helperMsg.interestBulanan : ""}{" "}
                </MDTypography>
              </Grid>
              <Grid item mb={2}>
                <MDInput
                  id="interest_tahunan"
                  name="interest_tahunan"
                  label="Bunga 1 Tahun (%)"
                  value={interestTahunan}
                  type="number"
                  onBlur={onInterestTahunanBlur}
                  onChange={(e) => setInterestTahunan(e.target.value)}
                  placeholder="Bunga per tahun "
                  required
                  sx={{ width: 300 }}
                />
                <MDTypography color="error" sx={{ fontSize: 10 }}>
                  {showError.interestTahunan ? helperMsg.interestTahunan : ""}{" "}
                </MDTypography>
              </Grid>
              <Grid item mb={2}>
                <MDInput
                  id="minimum_period"
                  name="minimum_period"
                  label="Durasi Pinjaman Minimum (hari)"
                  value={minimumPeriod}
                  type="number"
                  onBlur={onMinimumPeriodBlur}
                  onChange={(e) => setMinimumPeriod(e.target.value)}
                  placeholder="Durasi pinjaman minimum"
                  sx={{ width: 300 }}
                />
                <MDTypography color="error" sx={{ fontSize: 10 }}>
                  {showError.interestTahunan ? helperMsg.interestTahunan : ""}{" "}
                </MDTypography>
              </Grid>
              <Grid item mb={2}>
                <MDInput
                  id="penalti_bulanan"
                  name="penalti_bulanan"
                  label="Penalti per 30 hari (%)"
                  value={penaltiBulanan}
                  type="number"
                  onBlur={onPenaltiBulananBlur}
                  onChange={(e) => setPenaltiBulanan(e.target.value)}
                  placeholder="Penalti tiap 30 hari "
                  sx={{ width: 300 }}
                />
                <MDTypography color="error" sx={{ fontSize: 10 }}>
                  {showError.interestTahunan ? helperMsg.interestTahunan : ""}{" "}
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>



      <Grid container
      // justifyContent="end" alignItems="end"   px={3}
      // spacing={3}
      >
        <Grid item>
          <MDBox borderRadius="xl" sx={{ width: "100%", mt: 1, px: 2, pt: 2, pb: 2, border: "1px solid #e1e1e1" }}>
            <Grid container
              // justifyContent="end" alignItems="end"   px={3}
              spacing={3}>
              <Grid item>
                <MDTypography color="black" fontSize="16px" fontWeight="medium" pb={0}>
                  Biaya Awal
                </MDTypography>
                <MDTypography color="light-grey" fontSize="12px" sx={{ fontStyle: 'italic' }} pb={3}>
                  *biaya yang akan ditagihkan di awal persetujuan
                </MDTypography>
                <MDBox sx={{ width: "100%", mt: 0 }}>
                  <Grid
                    container
                    // direction="column"
                    // alignItems="center"
                    // justifyContent="space-around"
                    spacing={2}
                  >
                    <Grid item mb={2}
                    // xs={12} md={6}
                    >
                      <MDInput
                        id="admin_fee"
                        name="admin_fee"
                        label="Biaya Admin/Provisi"
                        value={adminFee}
                        onChange={(e) => setAdminFee(e.target.value)}
                        onBlur={onKreditLimitBlur}
                        onFocus={onKreditLimitFocus}
                        placeholder="Biaya Admin"
                        sx={{ width: 300 }}
                      />
                    </Grid>
                    <Grid item mb={2}>
                      <Autocomplete
                        disablePortal
                        id="platform_fee_unit"
                        options={unit}
                        value={adminFeeUnit}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        onChange={onAdminFeeUnitChange}
                        sx={{ width: 300, px: 0 }}
                        renderInput={(params) => <TextField {...params} label="Satuan Biaya Admin" />}
                      />
                    </Grid>

                  </Grid>
                </MDBox>
              </Grid>

              <Grid item>
                <MDTypography color="black" fontSize="16px" fontWeight="medium" pb={0}>
                  Biaya Bulanan (Installment)
                </MDTypography>
                <MDTypography color="light-grey" fontSize="12px" sx={{ fontStyle: 'italic' }} pb={3}>
                  *biaya yang akan ditagihkan setiap bulan
                </MDTypography>
                <MDBox sx={{ width: "100%", mt: 0 }}>
                  <Grid
                    container
                    // direction="column"
                    // alignItems="center"
                    // justifyContent="space-around"
                    spacing={2}
                  >
                    <Grid item
                    // xs={12} md={6}
                    >
                      <MDInput
                        id="monthly_admin_fee"
                        name="monthly_admin_fee"
                        label="Biaya Admin Bulanan"
                        value={monthlyAdminFee}
                        onChange={(e) => setMonthlyAdminFee(e.target.value)}
                        // onBlur={onKreditLimitBlur}
                        // onFocus={onKreditLimitFocus}
                        placeholder="Biaya Admin"
                        sx={{ width: 300 }}
                      />
                    </Grid>
                    <Grid item
                      xs={12} md={6}
                    >
                      <Autocomplete
                        disablePortal
                        id="monthly_admin_fee_unit"
                        options={unit}
                        value={monthlyAdminFeeUnit}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        onChange={onMonthlyAdminFeeUnitChange}
                        sx={{ width: 300, px: 0 }}
                        renderInput={(params) => <TextField {...params} label="Satuan Biaya Admin" />}
                      />
                    </Grid>

                    <Grid item mb={2}
                    >
                      <MDBox borderRadius="xl" sx={{ width: "100%", px: 1, pl: 2, border: "1px solid #e1e1e1" }}>

                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox
                              checked={monthlyInstallment}
                              onChange={onMonthlyInstallmentChange}
                            />}
                            label={<MDTypography color="black" fontSize="12px" pb={0}>
                              cicil bunga per bulan
                            </MDTypography>}
                          />
                        </FormGroup>
                      </MDBox>
                    </Grid>


                    {/* <Grid item mb={2}
                      xs={12} md={6}
                    >
                      <Autocomplete
                        disablePortal
                        id="platform_fee_unit"
                        options={unit}
                        value={adminFeeUnit}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        onChange={onAdminFeeUnitChange}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Satuan Biaya Admin" />} />
                    </Grid> */}

                    {/* <Grid item mb={2} xs={12} md={6}>
                      <MDInput
                        id="admin_fee"
                        name="admin_fee"
                        label="Biaya Admin/Provisi"
                        value={adminFee}
                        onChange={(e) => setAdminFee(e.target.value)}
                        onBlur={onKreditLimitBlur}
                        onFocus={onKreditLimitFocus}
                        placeholder="Biaya Admin"
                        sx={{ width: 300 }}
                      />
                    </Grid> */}
                  </Grid>
                </MDBox>
              </Grid>

              <Grid item>
                <MDTypography color="black" fontSize="16px" fontWeight="medium" pb={0}>
                  Biaya Penutup
                </MDTypography>
                <MDTypography color="light-grey" fontSize="12px" sx={{ fontStyle: 'italic' }} pb={3}>
                  *biaya yang akan ditagihkan di akhir pendanaan
                </MDTypography>
                <MDBox sx={{ width: "100%", mt: 0 }}>
                  <Grid
                    container
                    // direction="column"
                    // alignItems="center"
                    // justifyContent="space-around"
                    spacing={2}
                  >
                    <Grid item mb={2} xs={12} md={6}>
                      <MDInput
                        id="admin_fee"
                        name="admin_fee"
                        label="Biaya Admin"
                        value={adminFee}
                        onChange={(e) => setAdminFee(e.target.value)}
                        onBlur={onKreditLimitBlur}
                        onFocus={onKreditLimitFocus}
                        placeholder="Biaya Admin"
                        sx={{ width: 300 }}
                      />
                    </Grid>
                    <Grid item mb={2}>
                      <Autocomplete
                        disablePortal
                        id="admin_fee_unit"
                        options={unit}
                        value={adminFeeUnit}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        onChange={onAdminFeeUnitChange}
                        sx={{ width: 300, px: 0 }}
                        renderInput={(params) => <TextField {...params} label="Satuan Biaya Admin" />}
                      />
                    </Grid>

                  </Grid>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </Grid >

      </Grid >

      <Grid container justifyContent="end" alignItems="end" spacing={3} px={3}>
        <Grid item>
          <MDTypography color="error" my={1} px={1} sx={{ fontSize: 10 }}>
            {showError.all ? helperMsg.all : ""}{" "}
          </MDTypography>
          <MDTypography color="error" my={1} px={1} sx={{ fontSize: 10 }}>
            {showError.fail ? helperMsg.fail : ""}{" "}
          </MDTypography>
        </Grid>
      </Grid>
      <Grid container justifyContent="end" alignItems="end" spacing={3} px={3}>
        <Grid item>
          <MDButton
            variant="contained"
            color="info"
            mt={1}
            size="small"
            onClick={() => setOpenApproveDialog(true)}
          >
            Simpan
          </MDButton>
        </Grid>
      </Grid>
      <Dialog
        open={openApproveDialog}
        onClose={() => setOpenApproveDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <MDBox display="flex" ml={2} mt={2}>
          <Icon color="info" fontSize="large">
            check_circle_outline
          </Icon>
          <MDBox sx={{ fontSize: 18, fontWeight: "bold", my: "auto", ml: 2 }}>
            Daftar ke Pendana
          </MDBox>
        </MDBox>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah Anda yakin ingin menyetujui peminjam ini?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            onClick={() => setOpenApproveDialog(false)}
            variant="outlined"
            color="info"
            size="small"
          >
            Batal
          </MDButton>
          <MDButton onClick={onAgree} variant="contained" color="info" size="small" autoFocus>
            Setuju
          </MDButton>
        </DialogActions>
      </Dialog>
    </MDBox >
  );
}

export default PersetujuanPeminjam;
