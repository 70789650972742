
const access = {
    broker_broker: ["register_borrow", "new_invoice", "approve_reject_qp", "request_so_financing", "new_so", "edit_so", "publish_so", "update_attachment_so", "publish_invoice", "edit_invoice", "close_invoice", "send_invoice", "update_attachment_invoice", "request_invoice_financing", "close_invoice_financing", "new_po", "publish_po"],
    broker_finance: ["register_borrow", "new_invoice", "publish_invoice", "edit_invoice", "close_invoice", "send_invoice", "update_attachment_invoice"],
    broker_operation: ["new_so", "edit_so", "publish_so", "update_attachment_so", "new_po", "publish_po"],
    broker_manager: ["register_borrow", "approve_reject_qp", "request_invoice_financing", "request_so_financing", "close_invoice_financing"],
    borrower_borrower: ["register_borrow", "publish_invoice", "new_invoice", "edit_invoice", "close_invoice", "send_invoice", "update_attachment_invoice"],
    borrower_admin: ["register_borrow", "publish_invoice", "edit_invoice", "close_invoice", "send_invoice", "update_attachment_invoice"],
    vendor_vendor: ["new_invoice", "publish_invoice", "edit_invoice", "close_invoice", "update_attachment_invoice", "request_qp"],
    // , "request_invoice_financing" ],
    fintech_lender: ["payment_qp", "transfer_invoice_financing", "approve_reject_invoice_financing", "approve_reject_so_financing"],
    kentara_admin: ["new_invoice", "publish_invoice", "edit_invoice", "close_invoice", "send_invoice", "update_attachment_invoice"],
}

export default access;
