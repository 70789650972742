import { useState, useEffect } from "react";
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import CurrencyExchangeRoundedIcon from "@mui/icons-material/CurrencyExchangeRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LVInvoiceDataGrid from "components/LVInvoiceDataGrid";
import LVAuthentication from "components/LVAuthentication";
import PilihPendanaModal from "modals/pilihPendanaModal";
import QuickPayModal from "modals/quickPayModal";
import { array } from "prop-types";
import SendEmailModal from "modals/sendEmailModal";
import DaftarPelunasanInvoiceModal from "modals/daftarPelunasanInvoiceModal";
import data from "layouts/tables/data/authorsTableData";
import ReactGA from "react-ga4";
import { transformInvoices } from "../../common/Util";
import { fetchInvoices, initInvoices } from "../../actions";
import { getCompanyKYCStatus } from "../../actions/companyAction";
import SendEmailBulkModal from "modals/sendBulkEmailModal";

function InvoiceList() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/invoice-penjualan", title: "Invoice Penjualan" });
  }, []);

  const user = JSON.parse(localStorage.getItem("user"));
  const [openModal, setOpenModal] = useState(false);
  const [openModalQP, setOpenModalQP] = useState(false);
  const [openModalKirim, setOpenModalKirim] = useState(false);
  const [openModalSendBulk, setOpenModalSendBulk] = useState(false);
  const [openPelunasanInvoice, setOpenPelunasanInvoice] = useState(false);
  const [dataPengajuan, setDataPengajuan] = useState({});
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [open, setOpen] = useState(false);

  const invoices = useSelector((state) => state.invoices, shallowEqual);

  const anCompanyKycStatus = useSelector((state) => state.anCompanyKycStatus, shallowEqual);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCompanyKYCStatus());
    dispatch(fetchInvoices());
    return () => {
      dispatch(initInvoices());
    };
  }, []);

  useEffect(() => { }, [invoices]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClickPelunasan = () => {
    setOpenPelunasanInvoice(true);
  };

  const handleClickOpenModal = () => {
    setDataPengajuan(undefined);
    setOpenModal(true);
  };

  const handleOpenSendInvoiceModal = () => {
    setDataPengajuan(undefined);
    setOpenModalKirim(true);
  };

  const navigateToRegisterBorrow = () => {
    navigate("/registerborrow");
  };

  const onClickSendInvoice = () => {
    setOpenModalSendBulk(true);
  };

  const render_invoice_baru = () => {
    if (user?.access_list?.includes("new_invoice")) {
      return (
        <Grid item>
          <MDBox>
            <MDButton variant="contained" color="info" component={Link} to="/newinvoice">
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              &nbsp;Invoice Baru
            </MDButton>
          </MDBox>
        </Grid>
      );
    }
  };

  const render_pelunasan = () => {
    if (user?.access_list?.includes("request_invoice_financing")) {
      return (
        <Grid item>
          <MDBox>
            <MDButton variant="outlined" color="info" onClick={onClickPelunasan}>
              Pelunasan
            </MDButton>
          </MDBox>
        </Grid>
      );
    }
  };

  const render_invoice_financing = () => {
    if (user?.access_list?.includes("request_invoice_financing")) {
      return (
        <Grid item>
          <MDBox>
            <MDButton variant="outlined" color="info" onClick={onClickPendanaan}>
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              &nbsp;Ajukan Invoice Financing
            </MDButton>
          </MDBox>
        </Grid>
      );
    }
  };

  const render_send_invoice = () => (
    <Grid item>
      <MDBox>
        <MDButton variant="outlined" color="info" onClick={onClickSendInvoice}>
          <Icon fontSize="large" color="info" sx={{ fontWeight: "light" }}>
            mail_outline
          </Icon>
          &nbsp; Kirim Invoice
        </MDButton>
      </MDBox>
    </Grid>
  );

  const onClickPendanaan = () => {
    if (anCompanyKycStatus.status === "created") {
      handleClickOpen();
    } else if (anCompanyKycStatus.status === "approved") {
      if (user?.company?.business_type === "borrower") {
        handleClickOpenModal();
      } else if (user?.company?.business_type === "broker") {
        handleClickOpenModal();
      }
    } else {
      handleClickOpen();
    }
  };

  return (
    <div>
      <DashboardLayout>
        <SimpleDashboardNavbar name="Invoice Penjualan" />
        <MDBox mt={5} bgColor="white" p={0.5} shadow="md" borderRadius="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={2.4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="secondary"
                  icon="add_to_queue"
                  title="Draft"
                  count={124}
                  countVariant="h5"
                  percentage={{
                    color: "success",
                    amount: "+5%",
                    label: "dari minggu lalu",
                    type: "up",
                  }}
                  first
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2.4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="file_copy"
                  color="dark"
                  title="Terbit"
                  count="87"
                  countVariant="h5"
                  percentage={{
                    color: "primary",
                    amount: "-7%",
                    label: "dari minggu lalu",
                    type: "down",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2.4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="forward_to_inbox"
                  title="Dikirim"
                  count="66"
                  countVariant="h5"
                  percentage={{
                    color: "success",
                    amount: "+4%",
                    label: "dari minggu lalu",
                    type: "up",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2.4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="currency_exchange_rounded"
                  title="Bayar Cepat"
                  count="58"
                  countVariant="h5"
                  percentage={{
                    color: "success",
                    amount: "+23%",
                    label: "dari minggu lalu",
                    type: "up",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2.4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="hourglass_bottom"
                  title="Menunggu Bayar"
                  count="21"
                  countVariant="h5"
                  percentage={{
                    color: "success",
                    amount: "+7%",
                    label: "dari minggu lalu",
                    type: "up",
                  }}
                  last
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3} pt={3} justifyContent="space-between">
          <Grid item />
          <Grid item width="90%">
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <MDBox pr={2} my="auto">
                  <MDInput label="Cari disemua kolom.." sx={{ width: "300px" }} />
                </MDBox>
              </Grid>
              {render_send_invoice()}
              {render_pelunasan()}
              {render_invoice_financing()}
              {render_invoice_baru()}
            </Grid>
          </Grid>
        </Grid>
        <MDBox sx={{ mt: 3 }}>
          <LVInvoiceDataGrid
            rows={transformInvoices(invoices)}
            height={650}
            pageSize={10}
            rowsPerPageOptions={[10]}
            checkboxSelection
            columnType={user?.company?.business_type}
            requestFinancing={user?.access_list?.includes("request_invoice_financing")}
            onSendEmail={(dataInvoice) => {
              setSelectedInvoice(dataInvoice);
              setOpenModalKirim(true);
            }}
            onPilihPendana={(e) => {
              if (user?.company?.business_type === "vendor") {
                setDataPengajuan(e);
                setOpenModalQP(true);
              } else if (anCompanyKycStatus.status === "created") {
                handleClickOpen();
              } else if (anCompanyKycStatus.status === "approved") {
                setDataPengajuan(e);
                setOpenModal(true);
              } else {
                handleClickOpen();
              }
            }}
          />
        </MDBox>
        <PilihPendanaModal
          open={openModal}
          datapengajuan={dataPengajuan}
          onClose={(e) => {
            setOpenModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        />
        <QuickPayModal
          open={openModalQP}
          datapengajuan={dataPengajuan}
          onClose={(e) => {
            setOpenModalQP(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Informasi</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Anda harus mendaftar pengajuan dana terlebih dahulu
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={navigateToRegisterBorrow} autoFocus>
              Daftar Pengajuan Dana
            </MDButton>
            <MDButton onClick={handleClose}>Batal</MDButton>
          </DialogActions>
        </Dialog>
        <DaftarPelunasanInvoiceModal
          open={openPelunasanInvoice}
          onClose={(e) => {
            setOpenPelunasanInvoice(false);
          }}
          type="invoice-penjualan"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        />
        <SendEmailModal
          open={openModalKirim}
          onClose={(e) => setOpenModalKirim(false)}
          invoiceid={selectedInvoice.invoice_id}
          invoicenumber={selectedInvoice.invoice_number}
          invoiceamount={Number(
            selectedInvoice.invoice_amount ? selectedInvoice.invoice_amount : 0
          ).toLocaleString("id-ID", { style: "currency", currency: "IDR" })}
          customercompanyid={selectedInvoice.invoiced_company_id}
          // vadata={null}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        />
        <SendEmailBulkModal
          open={openModalSendBulk}
          onClose={(e) => setOpenModalSendBulk(false)}
          rows={invoices}
        />
      </DashboardLayout>
    </div>
  );
}

export default InvoiceList;
